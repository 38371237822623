<template>
    <div class="DailySettleDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" :inline="true" size="small" label-width="100px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="交易机构">
                            <el-input placeholder="" readOnly="true" v-model="detail.deptName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结算日期">
                            <el-date-picker
                                style="width: 200px"
                                readonly
                                v-model="detail.settleDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收银员">
                            <el-input placeholder="" readOnly="true" v-model="detail.cashierName" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input placeholder="" readOnly="true" v-model="detail.creator" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收银笔数">
                            <el-input placeholder="" readOnly="true" v-model="detail.tradeCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="原价总计">
                            <el-input placeholder="" readOnly="true" v-model="detail.totalOriginalPrice" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="优惠汇总">
                            <el-input placeholder="" readOnly="true" v-model="detail.totalFavour" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="抹零汇总">
                            <el-input placeholder="" readOnly="true" v-model="detail.totalFloor" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="应收总额">
                            <el-input placeholder="" readOnly="true" v-model="detail.totalPay" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="退款笔数">
                            <el-input placeholder="" readOnly="true" v-model="detail.refundCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="应退总额">
                            <el-input placeholder="" readOnly="true" v-model="detail.needRefund" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="整单优惠汇总">
                            <el-input placeholder="" readOnly="true" v-model="detail.refundPreference" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="实退总额">
                            <el-input placeholder="" readOnly="true" v-model="detail.totalRefund" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="(自)退款笔数">
                            <el-input placeholder="" readOnly="true" v-model="detail.selfRefundCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="(自)实退总额">
                            <el-input placeholder="" readOnly="true" v-model="detail.totalSelfRefund" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" readonly />
                    </el-col>
                </el-row>
            </el-form>
            <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>

            <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.finance.dailySettle.review'" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border style="width: 100%" :data="detail.pays">
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="支付类型" width="200" prop="templateName" />
                <el-table-column label="应收总额" width="144" prop="money">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.money | money }}</span></template
                    >
                </el-table-column>
                <el-table-column label="实收总额" width="144" prop="realMoney">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.realMoney | money }}</span></template
                    >
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import EfRemark from 'components/EfRemark';
import PickupReturnAudit from '../../../components/PickupReturnAudit';

export default {
    name: 'DailySettleDetail',
    components: { EfRemark, PickupReturnAudit },
    props: ['form'],
    data() {
        return {
            baseUrl: '/finance/settle/daily',
            tableData: [],
            url: {
                queryDetail: '/finance/settle/daily/info_c/',
            },
            meta: {
                types: [],
            },
            detail: {
                pays: [],
            },
        };
    },
    mounted() {
        const _this = this;
        _this.handleQuery();
    },
    methods: {
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.form.code, this.baseUrl);
        },
        handleQuery() {
            const _this = this;
            Util.queryTable(_this, _this.url.queryDetail + _this.form.code, null, (data) => {
                _this.detail = data.data;
                _this.detail.needRefund = _this.fmtMoney(data.data.needRefund);
                _this.detail.refundPreference = _this.fmtMoney(data.data.refundPreference);
                _this.detail.totalFavour = _this.fmtMoney(data.data.totalFavour);
                _this.detail.totalFloor = _this.fmtMoney(data.data.totalFloor);
                _this.detail.totalOriginalPrice = _this.fmtMoney(data.data.totalOriginalPrice);
                _this.detail.totalPay = _this.fmtMoney(data.data.totalPay);
                _this.detail.totalRefund = _this.fmtMoney(data.data.totalRefund);
                _this.detail.totalSelfRefund = _this.fmtMoney(data.data.totalSelfRefund);
            });
        },
    },
};
</script>

<style scoped>
.dailySettle_detail .el-form-item {
    margin-bottom: 0;
}
</style>
